import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const CustomerDropDown = ({ customers, onCustomerSelect, sx, error }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleChange = (event) => {
    const selectedCustomerAssetId = event.target.value;
    const customer = customers.find(
      (c) => c.CustomerAssetId === selectedCustomerAssetId
    );
    setSelectedCustomer(customer);
    if (customer) {
      onCustomerSelect(customer);
    }
  };

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="customer-label">Customer</InputLabel>
      <Select
        labelId="customer-label"
        label="Customer"
        value={selectedCustomer ? selectedCustomer.CustomerAssetId : ""}
        onChange={handleChange}
        error={error}
      >
        {customers.map((customer) => (
          <MenuItem
            key={customer.CustomerAssetId}
            value={customer.CustomerAssetId}
          >
            {customer.CustomerName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomerDropDown;
