import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import castrol from './castrol_8.jpg';
import {useIsAuthenticated } from "@azure/msal-react";
import { msalInstance } from './utils';
import CustomerPortalUrl from './components/CustomerPortalUrl';
import CustomButton from './components/CustomButton';
import { LOGIN_REQUEST } from './utils.js'
import Box from "@mui/material/Box";

export default function SignInSide() {

  const isAuthenticated = useIsAuthenticated();

  function handleLogin(instance) {
    instance.loginPopup(LOGIN_REQUEST).catch(e => {
      console.error(e);
    });
  }

  return (
    <Grid container sx={{ height: "100%" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundImage: `url(${castrol})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top'
          }}
        />
        <Grid item xs={12} sm={6} md={6}>
        {
            isAuthenticated ? <CustomerPortalUrl /> :
              <Box
                sx={{
                  mx: 4,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly'
                }}
              >
                <CustomButton onClick={() => handleLogin(msalInstance)}>Sign in to Castrol</CustomButton>
              </Box>
        }
        </Grid>
      </Grid>
  );
}
