import React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const UseCaseDropdown = ({ useCase, sx, onUseCaseSelect }) => {
  const [selectedUseCase, setSelectedUseCase] = React.useState(useCase);

  const handleChange = (event) => {
    setSelectedUseCase(event.target.value);
    onUseCaseSelect(event.target.value);
  };

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="usecase-label">Use Case</InputLabel>
      <Select
        labelId="usecase-label"
        label="Use Case"
        value={selectedUseCase}
        onChange={handleChange}
      >
        <MenuItem value="Marine">Marine</MenuItem>
        <MenuItem value="Industrial">Industrial</MenuItem>
        <MenuItem value="Smart Control">Smart Control</MenuItem>
      </Select>
    </FormControl>
  );
};

export default UseCaseDropdown;
