import * as React from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import isEmail from "validator/lib/isEmail";

import { submitRegistration, getCustomers } from "../service/service";
import { Button, CircularProgress, Modal } from "@mui/material";
import UseCaseDropdown from "./UseCaseDropDown";
import RoleDropdown from "./RoleDropDown";
import CustomerDropDown from "./CustomerDropDown";
import NestedCustomerDropDown from "./NestedCustomerDropDown";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.castrol.com/">
        Castrol
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function AdminPortalUrl() {
  const [firstName, setFirstName] = React.useState("");
  const [fnError, setFnError] = React.useState(false);
  const [lastName, setLastName] = React.useState("");
  const [lnError, setLnError] = React.useState(false);
  const [cnError, setCnError] = React.useState(false);
  const [idpError, setIdpError] = React.useState(null);
  const [customerError, setCustomerError] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emError, setEmError] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [useCase, setUseCase] = React.useState("Marine");
  const [role, setRole] = React.useState("Superintendent");
  const [customer, setCustomer] = React.useState(undefined);
  const [selectedParentIds, setSelectedParentIds] = React.useState([]);
  React.useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const customersResponse = await getCustomers();
        const sortedCustomers = customersResponse.sort((c1, c2) =>
          c1.CustomerName.localeCompare(c2.CustomerName)
        );
        setCustomers(sortedCustomers);
      } catch (error) {
        console.error("Error fetching customers: ", error);
      }
    };

    fetchCustomers();
  }, []);

  React.useEffect(() => {
    setSelectedParentIds([]);
  }, [role, customer]);
  React.useEffect(() => {
    setCustomer(undefined);
  }, [useCase]);
  const resetFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setEmError(false);
  };

  const closeModal = () => {
    setOpenModal(false);
    setIdpError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fne = firstName.trim() === "";
    const lne = lastName.trim() === "";
    const eme = !isEmail(email);
    const customerError =
      customer === undefined && role.toLowerCase() !== "tse";
    setFnError(fne);
    setLnError(lne);
    setEmError(eme);
    setCustomerError(customerError);
    console.log(
      fne + " " + lne + " " + eme + " " + email + " " + customerError
    );
    if (!(fne || lne || eme || inProgress || customerError)) {
      try {
        setOpenModal(true);
        let roleName = role;
        if (role.toLowerCase() === "tse") {
          if (useCase.toLowerCase() === "marine") {
            roleName = "MARINE_TSE";
          } else if (
            useCase.toLowerCase() === "industrial" ||
            useCase.toLowerCase() === "smart control"
          ) {
            roleName = "INDUSTRIAL_TSE";
          }
        }
        const result = await submitRegistration(
          firstName,
          lastName,
          email,
          customer?.CustomerAssetId,
          selectedParentIds,
          roleName,
          setInProgress
        );
        console.log(result);
        resetFields();
      } catch (e) {
        setInProgress(false);
        handleSubmitError(e);
      }
    }
  };

  const handleSubmitError = (e) => {
    if (e.response && e.response.data) {
      setIdpError(
        e.response.data.message ? e.response.data.message : e.response.data
      );
    } else if (e.message) {
      setIdpError(e.message);
    } else {
      console.log(e.stackTrace);
      setIdpError("An unexpected error has occurred.");
    }
  };
  const fieldSx = {
    mt: 3,
    width: "100%",
  };

  const buttonSx = {
    "border-radius": "128px",
    "background-color": "#529466",
    color: "white",
    mt: 3,
    width: "70%",
    align: "center",
    ":hover": {
      bgcolor: "#3CB371",
      color: "white",
    },
  };

  const modalSx = {
    position: "absolute",
    top: "50%",
    left: "75%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #009343",
    boxShadow: 24,
    p: 4,
  };

  const isDisplayHierarchy = React.useMemo(() => {
    if (!customer) return false;
    if (role === "TSE") return false;
    return true;
  }, [customer, role]);

  const hierarchyData = React.useMemo(() => {
    function transform(hierarchy) {
      if (!hierarchy) {
        return;
      }
      return {
        label: hierarchy.assetName,
        id: hierarchy.assetId,
        delay: 300,
        children: hierarchy.children?.map((child) => transform(child)),
      };
    }
    if (customer) {
      return [transform(customer.AssetHierarchy)];
    }
  }, [customer]);

  return (
    <Box
      sx={{
        mx: 4,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Grid container sx={{ width: "70%", justifyContent: "center" }}>
        <Typography
          component="h5"
          variant="h5"
          sx={{ fontFamily: "IBM Plex Sans, sans-serif;", fontWeight: "bold" }}
          align="center"
        >
          Let's Register a User
        </Typography>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            label="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            sx={fieldSx}
            variant="outlined"
            color="success"
            value={firstName}
            error={fnError}
            required
          />
          <TextField
            label="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            sx={fieldSx}
            variant="outlined"
            color="success"
            value={lastName}
            error={lnError}
            required
          />
          <TextField
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            sx={fieldSx}
            variant="outlined"
            color="success"
            value={email}
            error={emError}
            required
          />
          <UseCaseDropdown
            useCase={useCase}
            sx={fieldSx}
            onUseCaseSelect={setUseCase}
          />

          <RoleDropdown role={role} sx={fieldSx} onRoleSelect={setRole} />
          {role !== "TSE" && (
            <CustomerDropDown
              customers={customers.filter((c) => c.CustomerType === useCase)}
              onCustomerSelect={setCustomer}
              sx={fieldSx}
              error={customerError}
            />
          )}
          {isDisplayHierarchy && (
            <NestedCustomerDropDown
              title={"Choose accessible parent assets"}
              multiSelect={true}
              checkboxSelection={true}
              items={hierarchyData}
              onSelectedItemsChange={(ids) => setSelectedParentIds(ids)}
              sx={fieldSx}
            />
          )}

          <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalSx}>
              {!inProgress ? (
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  align="center"
                  color={idpError ? "error" : "success"}
                >
                  {idpError ? "" + idpError : "Complete"}
                </Typography>
              ) : (
                <Typography align="center">
                  <CircularProgress color="success" />
                </Typography>
              )}
            </Box>
          </Modal>
          <Typography align="center">
            <Button type="submit" variant="contained" sx={buttonSx}>
              Register
            </Button>
          </Typography>
        </form>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Box>
  );
}
