import axios from "axios";
import { msalInstance, refreshAccessToken } from "../utils";

const baseUrl = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "http://localhost:8081/api";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

const reqInterceptor = async (req) => {
  const token = await refreshAccessToken();
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

const resInterceptor = (res) => {
  return res;
};

axiosInstance.interceptors.request.use(reqInterceptor, (error) => {
  console.error(error);
  Promise.reject(error);
});
axiosInstance.interceptors.response.use(resInterceptor, (err) => {
  if (err?.response?.status === 401) {
    msalInstance.logoutRedirect();
  } else {
    console.error(err);
    return Promise.reject(err);
  }
});

export const submitRegistration = async (
  firstName,
  lastName,
  email,
  customerAssetId,
  allowedAssetIds,
  roleName,
  setInProgress
) => {
  setInProgress(true);
  const response = await axiosInstance.post(`/admin/register`, {
    firstName,
    lastName,
    email,
    customerAssetId,
    allowedAssetIds,
    roleName,
  });
  setInProgress(false);
  return response;
};

export const getCustomers = async () => {
  const response = await axiosInstance.get("/admin/customers");
  return response.data;
};
