import { styled } from '@mui/system';

const green = {
  500: '#529466',
  600: '#3CB371'
};


const CustomButtonRoot = styled('span')`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${green[500]};
  padding: 12px 24px;
  margin-bottom: 15px;
  border-radius: 24px;
  color: white;
  width: 70%;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  text-align: center;

  &:hover {
    background-color: ${green[600]};
  }
`;

export default function CustomButton(props) {
  return <CustomButtonRoot {...props}/>;
}