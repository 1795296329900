import React from "react";
import Box from "@mui/material/Box";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks";
import Typography from "@mui/material/Typography";

function getItemDescendantsIds(item) {
  const ids = [];
  item?.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

const NestedCustomerDropDown = ({
  title,
  items,
  multiSelect,
  checkboxSelection,
  onSelectedItemsChange,
  sx,
}) => {
  const [selectedItems, setSelectedItems] = React.useState([]);
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    setSelectedItems(newSelectedItems);

    // Select / unselect the children of the toggled item
    const itemsToSelect = [];
    const itemsToUnSelect = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId]
        )
      )
    );

    setSelectedItems(newSelectedItemsWithChildren);

    toggledItemRef.current = {};
  };

  React.useEffect(() => {
    let allSelectedItems = [...selectedItems];
    let itemsToUnselect = [];
    allSelectedItems.forEach((selectedItemId) => {
      const item = apiRef.current.getItem(selectedItemId);
      const descendentItems = getItemDescendantsIds(item);
      itemsToUnselect = [...itemsToUnselect, ...descendentItems];
    });
    const newSelectedParentItemIds = allSelectedItems.filter(
      (itemId) => !itemsToUnselect.includes(itemId)
    );
    onSelectedItemsChange(newSelectedParentItemIds);
  }, [selectedItems]);
  return (
    <Box sx={{ minHeight: 352, minWidth: 290, ...sx }}>
      {title && <Typography variant="h5">{title}</Typography>}
      <RichTreeView
        multiSelect={multiSelect}
        checkboxSelection={checkboxSelection}
        apiRef={apiRef}
        items={items}
        selectedItems={selectedItems}
        onSelectedItemsChange={handleSelectedItemsChange}
        onItemSelectionToggle={handleItemSelectionToggle}
      />
    </Box>
  );
};

export default NestedCustomerDropDown;
