import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';


const CLOUD_INSTANCE = process.env.REACT_APP_MSAL_CLOUD_INSTANCE ? process.env.REACT_APP_MSAL_CLOUD_INSTANCE : 'https://login.microsoftonline.com';
const TENANT_INFO = process.env.REACT_APP_MSAL_TENANT_INFO ? process.env.REACT_APP_MSAL_TENANT_INFO  : 'ea80952e-a476-42d4-aaf4-5457852b0f7e';
const REDIRECT_URI = process.env.REACT_APP_MSAL_REDIRECT_URI ? process.env.REACT_APP_MSAL_REDIRECT_URI : '';
const SUBDIRECTORY_URI = process.env.REACT_APP_SUBDIRECTORY_URI ? process.env.REACT_APP_SUBDIRECTORY_URI : '';
const CLIENT_ID = process.env.REACT_APP_MSAL_CLIENTID ? process.env.REACT_APP_MSAL_CLIENTID : '3ef7e72f-d177-4341-b2ef-75066a160c86';

export const MSAL_CONFIG = {
  auth: {
    clientId: CLIENT_ID,
    authority: `${CLOUD_INSTANCE}/${TENANT_INFO}`,
    redirectUri: `${REDIRECT_URI}${SUBDIRECTORY_URI}`,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_MSAL_CACHE_LOCATION || 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

export const LOGIN_REQUEST = {
  scopes: [`${CLIENT_ID}/.default`],
};

export const refreshAccessToken = async () => {
  try {
    const account = msalInstance.getAllAccounts()[0];
    const token = await msalInstance.acquireTokenSilent({
      account,
      scopes: LOGIN_REQUEST.scopes,
    });
    return token.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenPopup(LOGIN_REQUEST)
        .then(resp => {
          return resp.accessToken;
        })
        .catch(err => {
          msalInstance.logoutRedirect();
        });
    } else {
      msalInstance.logoutRedirect();
    }
  }
};
