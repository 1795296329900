import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import SignInSide from './SignInSide'
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './utils';


ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <SignInSide />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

