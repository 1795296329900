import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const RoleDropdown = ({ role, sx, onRoleSelect }) => {
  const [selectedRole, setSelectedRole] = useState(role);

  const handleChange = (event) => {
    setSelectedRole(event.target.value);
    onRoleSelect(event.target.value);
  };

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="role-label">Role</InputLabel>
      <Select
        labelId="role-label"
        label="Role"
        value={selectedRole}
        onChange={handleChange}
      >
        <MenuItem value="Superintendent">SuperIntendent</MenuItem>
        <MenuItem value="Chief Engineer">Chief Engineer</MenuItem>
        <MenuItem value="TSE">TSE</MenuItem>
      </Select>
    </FormControl>
  );
};

export default RoleDropdown;
